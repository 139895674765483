import moment from 'moment';
type DateType = 'week' | 'month' | 'year' | 'custom'
interface Source {
    pd_count_in: number;
    pd_count_out: number;
    time: string;
}
export function getOptions(
    type: DateType,
    source: Source[],
    customtime?: { start_time: string; end_time: string }
) {
    let xAxisArr: any[] = []
    let pd_count_in: number[] = []
    let pd_count_out: number[] = []
    if (type == 'week') {
        xAxisArr = []
        for (let i = 0; i < 7; i++) {
            xAxisArr.unshift(moment().subtract(i, "days").format("YYYY-MM-DD"))
        }
    } else if (type == 'month') {
        xAxisArr = []
        for (let i = 1; i <= moment().daysInMonth(); i++) {
            xAxisArr.unshift(moment().subtract(i, "days").format("YYYY-MM-DD"))
        }
    } else if (type == 'year') {
        for (let i = 0; i < 12; i++) {
            xAxisArr.unshift(moment().subtract(i, "months").format("YYYY-MM"))
        }
    } else if (type == 'custom') {
        xAxisArr = []
        const length = moment(customtime?.end_time).diff(moment(customtime?.start_time), 'days')

        for (let i = 0; i <= length; i++) {
            xAxisArr.unshift(moment(customtime?.end_time).subtract(i, 'days').format("YYYY-MM-DD"))
        }
    }
    pd_count_in = []
    pd_count_out = []
    xAxisArr.forEach(key => {
        pd_count_in.push(source.find(i => i.time == key)?.pd_count_in || 0)
    })

    xAxisArr.forEach(key => {
        pd_count_out.push(source.find(i => i.time == key)?.pd_count_out || 0)
    })

    return {
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisArr,
        },
        legend: {
            left: 50,
            data: ['入库单量', '出库单量'],
        },
        yAxis: {
            type: 'value',
        },
        tooltip: {
            trigger: 'axis',
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        series: [
            {
                name: '入库单量',
                type: 'line',
                data: pd_count_in,
                areaStyle: {
                    origin: 'start',
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: 'rgba(78,166,245,30)', // 0% 处的颜色
                                //rgba(103, 194, 58, 0.3000)
                            },
                            {
                                offset: 1,
                                color: 'rgba(78,166,245,0)', // 100% 处的颜色
                            },
                        ],
                        global: false, // 缺省为 false
                    },
                },
            },
            {
                name: '出库单量',
                type: 'line',
                data: pd_count_out,
                areaStyle: {
                    origin: 'start',
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: 'rgba(103,194,58,30)', // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: 'rgba(103,194,58,0)', // 100% 处的颜色
                            },
                        ],
                        global: false, // 缺省为 false
                    },
                },
            },
        ],
    }
}