import { request } from '@/util/request';
import { message } from 'ant-design-vue';
const getUrl = require('bmh/url-with-param');
export async function getFactoryHomeData() {
    const url = `/boss/factory/home`
    const res = await request.get(url)
    if (res.status == 200) {
        return res.data
    }
    return false
}

export async function getStatsWarehouse(
    factory_id: number | string,
    warehouse_id: number | string,
    type: number | string,
    start_time?: number | string,
    end_time?: number | string) {
    const url = getUrl(`/boss/factory/stats/warehouse`,
        type != 4 ? {
            factory_id,
            warehouse_id,
            type,
        } : {
            factory_id,
            warehouse_id,
            type,
            start_time,
            end_time
        })
    const res = await request.get(url)
    if (res.status == 200) {
        return res.data
    }
    return false
}
